<template>
  <div id="tableCbt">
    <a-alert
      v-if="status.status_spp === 'unpaid'"
      message="Warning"
      description="If you want to take the exam, make sure you have completed school administration."
      type="warning"
      show-icon
      class="mb-4"
    />
    <div class="d-lg-flex mb-5 mb-lg-2">
      <a-form-item class="mr-lg-3">
        <a-input
          v-model="searchCBT"
          @keyup="searchCBTList"
          placeholder="Search by Subject..."
          style="height: 40px"
        ></a-input>
      </a-form-item>
      <a-form-item class="mr-lg-3">
        <a-select
          @change="handleSelectType"
          size="large"
          :class="!isDesktop ? 'w-100' : null"
          style="min-width: 100px"
          default-value="All"
        >
          <a-select-option value="">All Type</a-select-option>
          <a-select-option value="PH">PH</a-select-option>
          <a-select-option value="PTS">PTS</a-select-option>
          <a-select-option value="PAS">PAS</a-select-option>
          <a-select-option value="Others">Others</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button
          @click.prevent="refreshTable"
          type="default"
          style="width: 100%; height: 40px"
        >
          <a-icon type="sync" :spin="loadingRefresh" />Refresh
        </a-button>
      </a-form-item>
    </div>
    <a-table
      class="table-scroll"
      :columns="columns"
      :data-source="datas"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <a-icon slot="filterIcon" type="search" />
      <span
        :style="{
          cursor:
            record.statusAttendance !== 'done' && !isOnSEB
              ? 'not-allowed'
              : record.statusAttendance !== 'done' && isOnSEB
              ? 'pointer'
              : 'default',
        }"
        slot="Status"
        slot-scope="text, record"
      >
        <template v-if="record.isNow">
          <a
            v-if="record.statusAttendance === 'done'"
            disabled
            class="btn btn-info mx-2"
            >Closed</a
          >
          <a
            v-else
            :disabled="
              status.status_spp === 'unpaid' ||
              (isOnSEB && record.type !== 'PH')
            "
            @click.prevent="() => toExam(record.key, record.type)"
            :class="[
              'btn',
              'btn-outline-info',
              'mx-2',
              isOnSEB && record.type !== 'PH' ? 'disableBtn' : '',
            ]"
            >{{
              !isOnSEB && record.type !== 'PH' && record.type !== 'PPDB'
                ? 'Open SEB'
                : 'Take Exam'
            }}<img
              v-if="!isOnSEB && record.type !== 'PH' && record.type !== 'PPDB'"
              src="/resources/images/seb.png"
              :style="{
                width: '25px',
                height: '25px',
                objectFit: 'contain',
              }"
              class="ml-2"
              alt="SEB"
          /></a>
        </template>
        <template v-else>
          <p v-if="record.isUndone">
            <a-icon type="hourglass" />
            {{ findDayDifferenceUndone(record.momentDateStart) }}
          </p>
          <a v-else disabled class="btn btn-info mx-2">Closed</a>
        </template>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment'
import jwt from 'jsonwebtoken'

const columns = [
  {
    title: 'Subject',
    dataIndex: 'subject',
    // width: '15%',
    align: 'center',
    sorter: (a, b) => a.idMapel - b.idMapel,
    scopedSlots: {},
  },
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '10%',
    align: 'center',
  },
  {
    title: 'Duration (minutes)',
    dataIndex: 'duration',
    align: 'center',
    // sorter: true,
    // width: '10%',
    scopedSlots: {},
  },
  {
    // title: 'Date & Time',
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    sorter: (a, b) =>
      moment(a.date, 'dddd, MMM Do YYYY') - moment(b.date, 'dddd, MMM Do YYYY'),
    // sorter: true,
    // width: '20%',
    scopedSlots: {},
    // children: [{
    //   title: 'Start',
    //   dataIndex: 'startTime',
    //   align: 'center',
    //   // width: '12.5%',
    // }, {
    //   title: 'End',
    //   dataIndex: 'endTime',
    //   align: 'center',
    //   // width: '12.5%',
    // }],
  },
  {
    title: 'Time',
    // width: '25%',
    children: [
      {
        title: 'Start',
        dataIndex: 'startTime',
        align: 'center',
        // width: '12.5%',
      },
      {
        title: 'End',
        dataIndex: 'endTime',
        align: 'center',
        // width: '12.5%',
      },
    ],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    // width: '25%',
    scopedSlots: { customRender: 'Status' },
  },
]
export default {
  data() {
    return {
      columns,
      pagination: {},
      loading: false,
      searchCBT: '',
      selectType: '',
      order: '',
      sortBy: '',
    }
  },
  methods: {
    handleSelectType(value) {
      this.loading = true
      this.selectType = value
      this.pagination.current = 1
      this.$store
        .dispatch('cbt/FETCH_CBT_MURID_LIST', {
          idMurid: this.user.id,
          order: this.order,
          page: this.pagination.current,
          sortBy: this.sortBy,
          tipe: this.selectType,
          searchCBT: this.searchCBT,
        })
        .then((_) => {
          this.loading = false
        })
    },
    refreshTable() {
      this.loading = true
      this.$emit('changeLoadingRefresh', true)
      this.$store
        .dispatch('cbt/FETCH_CBT_MURID_LIST', {
          idMurid: this.user.id,
          order: this.order,
          page: this.pagination.current,
          sortBy: this.sortBy,
          tipe: this.selectType,
          searchCBT: this.searchCBT,
          isPpdb: localStorage.isPpdb,
        })
        .then((_) => {
          this.loading = false
          this.$emit('changeLoadingRefresh', false)
        })
    },
    handleTableChange(pagination, filters, sorter) {
      this.loading = true
      const pager = { ...this.pagination }
      // console.log('berubah')
      pager.total = this.totalCbtMurid
      pager.current = pagination.current
      pager.total = this.totalCbtMurid
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }

      if (sorter.field === 'subject') {
        this.sortBy = 'id_mata_pelajaran'
      } else if (sorter.field === 'date') {
        this.sortBy = 'waktu_ujian_dibuka'
      }
      this.$store
        .dispatch('cbt/FETCH_CBT_MURID_LIST', {
          idMurid: this.user.id,
          order: this.order,
          page: pagination.current,
          sortBy: this.sortBy,
          tipe: this.selectType,
          searchCBT: this.searchCBT,
        })
        .then((_) => {
          this.loading = false
        })
    },
    color(nilai) {
      if (nilai < 35) {
        return '#FF0000'
      } else if (nilai >= 35 && nilai < 70) {
        return 'orange'
      } else if (nilai >= 70 && nilai <= 100) {
        return '#41B883'
      } else {
        return '#FF0000'
      }
    },
    findDayDifferenceUndone(date) {
      if (moment().diff(moment(date, 'YYYY-DD-MM HH:mm'), 'days') === 0) {
        return `${moment(date, 'YYYY-DD-MM HH:mm').diff(
          moment(),
          'hours',
        )} hours to go`
      } else {
        const diffDay = moment(date, 'YYYY-DD-MM HH:mm').diff(moment(), 'days')
        return `${diffDay} day${diffDay === 1 ? '' : 's'} to go`
      }
    },
    async toExam(id, cbtType) {
      try {
        if (!this.isOnSEB && cbtType !== 'PH' && cbtType !== 'PPDB') {
          const a = document.createElement('a')
          const tokenUjian = await jwt.sign(
            { idUser: this.user.id, idCbt: id, cbtType },
            'ujian-hasan-digital',
          )
          // const url = `seb://${
          //   window.location.origin.split('//')[1]
          // }?token=${tokenUjian}`
          // console.log(url)
          const url = `sebs://cbt-smp-albayan.hasanteknologi.id/login?token=${tokenUjian}`
          // const url = `seb://localhost:8080/login?token=${tokenUjian}`
          // console.log(url, 'iniurl')
          // console.log(url2, 'iniurl2')
          a.href = url
          a.target = '_blank'
          await a.click()
          // console.clear()
        } else {
          localStorage.setItem('cbtType', cbtType)
          this.$store.commit('SET_STATE', {
            cbtType,
          })
          this.$router.push({ name: 'Cbt Start Exam Student', params: { id } })
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Cbt Student'],
          })
        }
      } catch (err) {
        // this.$notification.error({
        //   message: 'SEB Not Found',
        //   description:
        //     "It seems you didn't download SEB yet. Please donwload Safe Exasm Browser on your device to continue.",
        // })
        console.log('tessss')
        console.log(err)
      }
    },
    async searchCBTList() {
      this.loading = true
      await this.$store.dispatch('cbt/FETCH_CBT_MURID_LIST', {
        idMurid: this.user.id,
        searchCBT: this.searchCBT,
        tipe: this.selectType,
        sortBy: this.sortBy,
        order: this.order,
      })
      this.loading = false
    },
  },
  props: ['loadingRefresh'],
  created() {
    this.$store
      .dispatch('cbt/FETCH_CBT_MURID_LIST', {
        idMurid: this.user.id,
        isPpdb: localStorage.isPpdb,
      })
      .then(() => {
        this.pagination = {
          total: this.totalCbtMurid,
          pageSize: 10,
          hideOnSinglePage: true,
        }
      })
  },
  computed: {
    datas() {
      let arr = []
      if (this.listCBTMurid.length) {
        arr = this.listCBTMurid.map((cbt) => {
          const examStart = moment(cbt.waktu_ujian_dibuka)
          const examEnd = moment(cbt.waktu_ujian_ditutup)
          return {
            key: cbt.id,
            idMapel: cbt.id_mata_pelajaran,
            subject: cbt.mata_pelajaran.nama,
            duration: cbt.durasi,
            momentDateStart: examStart,
            momentDateEnd: examEnd,
            date: examStart.format('dddd, MMM Do YYYY'),
            startTime: moment(examStart, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            // startTime: moment(examStart, 'YYYY-MM-DD HH:mm').format('dddd, DD MMM YYYY HH:mm'),
            endTime: moment(examEnd, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            // endTime: moment(examEnd, 'YYYY-MM-DD HH:mm').format('dddd, DD MMM YYYY HH:mm'),
            isUndone: moment(examEnd, 'YYYY-MM-DD HH:mm').isAfter(moment()),
            isNow: moment().isBetween(
              moment(examStart, 'YYYY-MM-DD HH:mm'),
              moment(examEnd, 'YYYY-MM-DD HH:mm'),
              'miliseconds',
              '[)',
            ),
            statusAttendance: cbt.cbt_attendances[0].status_attendance,
            type: cbt.tipe,
          }
        })
      }

      return arr
    },
    totalCbtMurid() {
      return this.$store.state.cbt.totalCbtMurid
    },
    user() {
      return this.$store.state.user.user
    },
    status() {
      return this.$store.state.cbt.status || {}
    },
    isOnSEB() {
      return this.$store.state.isOnSEB
    },
    listCBTMurid() {
      return this.$store.state.cbt.listCBTMurid
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  watch: {
    totalCbtMurid(newVal, oldVal) {
      this.pagination.total = newVal
    },
  },
}
</script>
<style lang="scss">
#tableCbt {
  .disableBtn {
    color: gray;
    border-color: gray;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;

    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}

.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
